<template>
  <div>
    <template v-for="item in modules">
      <b-form-checkbox
        :class="{'mt-05': true, 'ml-2': item.parentID}"
        :value="item.moduleID"
        v-model="rowData.permissionIDs"
        @change="changeModule(item)"
      >
        {{item.name}}
      </b-form-checkbox>

    </template>
  </div>
</template>

<script>
import {BFormCheckbox} from 'bootstrap-vue'
import common from "@/common";

export default {
  name: "SlotModule",
  components: {
    BFormCheckbox
  },
  props: {
    rowData: {type: Object},
    facilityId: {type: Number}
  },
  data() {
    return {
      modules: [],
      relation: {}
    }
  },
  watch: {
    facilityId: function () {
      // this.rowData.permissionIDs = []
      this.modules = []
      this.getOptions()
    }
  },
  mounted() {
    this.getOptions()
  },
  methods: {
    getOptions: function () {
      const facility_id = this.facilityId ? this.facilityId : 0
      if(facility_id === 0) {
        this.modules = []
        return false
      }

      common.apiGetData('/facility/GetFacilityAvailableModules?facilityID='+facility_id).then(res => {
        this.modules = common.sortModules(res.data.modules)
        for (const i in this.modules) {
          if (this.modules[i].parentID) {
            if (!this.relation[this.modules[i].parentID]) {
              this.relation[this.modules[i].parentID] = []
            }
            this.relation[this.modules[i].parentID].push(this.modules[i].moduleID)
          }
        }

      })
    },

    changeModule: function (item) {
      const is_check = this.rowData.permissionIDs.includes(item.moduleID)

      if (!item.parentID && this.relation[item.moduleID]) {
        for (const i in this.relation[item.moduleID]) {
          const is_include = this.rowData.permissionIDs.includes(this.relation[item.moduleID][i])

          if (is_check && !is_include) {
            this.rowData.permissionIDs.push(this.relation[item.moduleID][i])
          } else if (!is_check && is_include) {
            const index = this.rowData.permissionIDs.indexOf(this.relation[item.moduleID][i])
            this.rowData.permissionIDs.splice(index, 1)
          }
        }
      } else if (item.parentID) {
        for (let i in this.relation) {
          i = parseInt(i)

          if (this.relation[i].includes(item.moduleID)) {
            if (is_check && !this.rowData.permissionIDs.includes(i)) {
              this.rowData.permissionIDs.push(i)
            } else if (!is_check) {
              let flag = true
              for (const k in this.relation[i]) {
                if (this.rowData.permissionIDs.includes(this.relation[i][k])) {
                  flag = false
                }
              }

              if (flag) {
                const index = this.rowData.permissionIDs.indexOf(i)
                this.rowData.permissionIDs.splice(index, 1)
              }

            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
